<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    /* if (!window.android) {
      let user_data = JSON.stringify({
        teacherId: "4a4af1d3333c4df8a3b89cc9620745ec",
        name: "T01",
      });
      let access_token = JSON.stringify({
        access_token:
          "eyJhbGciOiJIUzUxMiJ9.eyJzZXJ2ZXJfbmFtZSI6InpoanQtdGVhY2hlciIsInNjaG9vbF9pZCI6MiwidXNlcl9pZCI6IjRhNGFmMWQzMzMzYzRkZjhhM2I4OWNjOTYyMDc0NWVjIiwidXNlcl9rZXkiOiI0YWYwN2U2YS0yZmE2LTRmYTQtOTRhYy1iMjg2ODEwMzUyYmUiLCJ1c2VybmFtZSI6IlQwMSJ9.5qAdpwl-2RrZbXN5Ht5vAqF7u62sMdj3VbyIXzmQjZqRBAx_bpyIxe1wYZTN1qJW5hpOrGOqy6x0RHwAXYPfSQ",
        action: "submitLogin",
        expires_in: 43200,
      });
      sessionStorage.setItem("user_data", user_data);
      sessionStorage.setItem("access_token", access_token);
    } */
  },
  components: {},
};
</script>

<style>
#app {
  color: #000;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* * {
  touch-action: none;
} */

body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  font-size: 18px;
}
</style>
